import DispatchMap from './Dispatch/DispatchMap';
import GeneralMap from './General/GeneralMap';
export default {
  components: {
    DispatchMap,
    GeneralMap
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { map }) => {
      if (type == 'map/hasAuth') map.hasAuth ? location.reload() : (this.hasAuth = map.hasAuth);
      if (type == 'map/update') {
        this.update = map.update;
        const { action, data } = map?.update || {};
        if (action === 'darkMode') {
          this.isDarkMode = !!data;
        } else if (action && action != 'restore') {
          this.isDispatchMode = action === 'showDispatch';
        }
      }
    });
    window.addEventListener('beforeunload', () => this.aliveMap(false));
  },
  mounted() {
    this.aliveMapSetup();
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    const view = this.$route.query.view;
    return {
      hasAuth: this.$store.getters.hasAuth,
      isDarkMode: !!this.$store.getters.DARK,
      isDispatchMode: view === 'dispatch',
      unsubscribe: null,
      update: { action: '', data: null }
    };
  },
  methods: {
    aliveMapSetup() {
      this.aliveMap(true);
    },
    aliveMap(status) {
      this.$store.dispatch('map/isAlive', status);
      setInterval(() => {
        this.$store.dispatch('map/isAlive', status);
      }, 30000);
    },
    onResponse(data) {
      this.$store.commit('map/response', data);
    }
  },
  watch: {}
};
